import moment, { ISO_8601 } from "moment";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import logo from "statics/images/logo.png";
import {
  TEModal,
  TEModalBody,
  TEModalContent,
  TEModalDialog,
} from "tw-elements-react";
import * as SBService from "../../helpers/sportbookService";
import { getAssetFullUrl } from "../../helpers/utils";
import { loaderState, userState } from "../../states/common";
import Icon from "../icon";

interface LeagueListingItemProps {
  league: any;
  isJoined: boolean;
  isShowScanBtn: boolean;
}

const LeagueListingItem: React.FC<LeagueListingItemProps> = ({
  league,
  isJoined = false,
  isShowScanBtn = false,
}) => {
  const navigate = useNavigate();
  league.startDateObj = moment(league.startDate, ISO_8601);
  league.endDateObj = moment(league.endDate, ISO_8601);
  league.startRegistrationDateObj = moment(
    league.startRegistrationDate,
    ISO_8601
  );
  league.endRegistrationDateObj = moment(league.endRegistrationDate, ISO_8601);
  const [user, setUser] = useRecoilState(userState);
  const [loader, setLoader] = useRecoilState(loaderState);
  const [showModal, setShowModal] = useState(false);
  const [playerInLeague, setPlayerInleague] = useState<any>({
    id: null,
    isCheckin: false,
  });

  const now = moment();

  const navigateToScanQR = (event: any) => {
    event.preventDefault();
  };

  const navigateToLeaguePage = () => {
    if (!isShowScanBtn) {
      if (isJoined) {
        navigate(`/league/${league.id}/detail`);
      } else {
        navigate(`/league/${league.id}`);
      }
    }
  };

  const joinLeague = async (event: any) => {
    event.preventDefault();
    setLoader(true);
    let listJoined = user.leagues.map((league) => league.id);
    if (!listJoined.includes(league.id)) {
      try {
        await SBService.joinLeagueById(league.id);
        toast.success("Tham gia giải đấu thành công");
        let userLeagues = [
          ...user.leagues,
          { id: league.id, banner: league.banner, logo: league.logo },
        ];

        setUser({ ...user, leagues: userLeagues });
      } catch (e: any) {
        if (e.response && e.response.data && e.response.data.error) {
          switch (e.response.data.error) {
            case "leagueFull":
              toast.error("Lỗi, giải đã đủ cơ thủ tham gia");
              break;
            default:
              toast.error("Lỗi, " + e.response.data.error);
              break;
          }
        } else {
          toast.error("Không thể tham gia giải đấu");
        }
      }
    } else {
      toast.error("Có lỗi xảy ra: Bạn đã tham gia giải đấu");
    }
    setShowModal(false);
    setLoader(false);
  };

  const checkIn = async () => {
    setLoader(true);
    try {
      await SBService.checkinLeagueById(league.id);
      setPlayerInleague({ ...playerInLeague, ...{ isCheckin: true } });
      toast.success("Check-In thành công");
    } catch (e: any) {
      toast.error("Check-In thất bại");
    }
    setLoader(false);
  };

  const checkOut = async () => {
    setLoader(true);
    try {
      await SBService.checkoutLeagueById(league.id);
      setPlayerInleague({ ...playerInLeague, ...{ isCheckin: false } });
      toast.success("Check-out thành công");
    } catch (e: any) {
      toast.error("Check-out thất bại");
    }
    setLoader(false);
  };

  useEffect(() => {
    const loadUserRec = async () => {
      try {
        const userRec = await SBService.getPlayerInLeagueData(
          league.id,
          user.id
        );
        if (userRec && userRec.data) {
          setPlayerInleague(userRec.data);
        }
      } catch (e: any) {}
    };

    loadUserRec();
  }, []);
  return (
    <>
      <div
        className="w-full bg-[#1E1E32] p-3 mt-6 flex rounded"
        onClick={() => {
          if (isJoined) navigateToLeaguePage();
        }}
      >
        <div
          onClick={() => {
            if (!isJoined) navigateToLeaguePage();
          }}
        >
          <img
            className="rounded w-[110px] h-[110px]"
            src={league.logo ? getAssetFullUrl(league.logo) : logo}
          />
        </div>
        <div className="ml-2 w-[calc(100%_-_110px)] mt-2">
          <div className="flex">
            <div
              className=" w-[calc(100%-20px)]"
              onClick={() => {
                if (!isJoined) navigateToLeaguePage();
              }}
            >
              <h3 className="text-xl font-medium">{league.name}</h3>
              {/* <p className="font-light font-exo text-sm color-[#B0B4BA] mt-2">{league.startRegistrationDateObj.format('DD MMMM')} - {league.endRegistrationDateObj.format('DD MMMM')}</p> */}
              {league.startDateObj && (
                <div className="font-light font-exo text-sm color-[#B0B4BA] mt-2 flex">
                  <p className="w-[55px]">Thi đấu: </p>
                  <span>
                    {league.startDateObj.format("DD/MM/YY")} -{" "}
                    {league.endDateObj.format("DD/MM/YY")}
                  </span>
                </div>
              )}
            </div>
            <div
              className="text-right w-[20px] flex items-center justify-end"
              onClick={() => {
                if (!isJoined) navigateToLeaguePage();
              }}
            >
              <Icon icon={"chevron-right"} />
            </div>
          </div>
          {isShowScanBtn ? (
            <a
              className="inline-block btn-primary accent-color w-full mt-3 text-center font-exo uppercase py-1 font-sm"
              onClick={() => navigate(`/scan-qr/${league.id}`)}
            >
              Quét Ngay
            </a>
          ) : (
            <>
              {isJoined ? (
                <>
                  {playerInLeague.id ? (
                    <div className="mt-1">
                      {" "}
                      {playerInLeague.isCheckin ? (
                        <button
                          className="btn w-full btn-primary text-white py-2 heading-6"
                          onClick={() => checkOut()}
                        >
                          Check-Out
                        </button>
                      ) : (
                        <button
                          className="btn w-full btn-primary text-white py-2 heading-6"
                          onClick={() => checkIn()}
                        >
                          Check-In
                        </button>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  {now.isAfter(league.endRegistrationDateObj) ||
                  now.isBefore(league.startRegistrationDateObj) ? (
                    <span className="text-sm text-danger font-exo heading-5">
                      Hiện đang không trong thời gian đăng ký giải đấu.
                    </span>
                  ) : (
                    <a
                      className="inline-block btn-primary accent-color w-full mt-3 text-center font-exo py-1 font-sx"
                      onClick={(event) => setShowModal(true)}
                    >
                      Đăng ký{" "}
                      {league.endRegistrationDateObj
                        ? `(hạn chót ${league.endRegistrationDateObj.format(
                            "DD/MM/YY"
                          )})`
                        : ""}
                    </a>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      <TEModal className="zIndexTop" show={showModal} setShow={setShowModal}>
        <TEModalDialog className="h-full" centered={true}>
          <TEModalContent>
            <TEModalBody className="bg-[#1E1E32] text-center border border-[#084843] rounded">
              <div className="w-full text-left border border-dotted rounded-[0.5rem] py-2 px-4 border-[#4D3000] bg-[#16120C]">
                <p className="font-exo text-sm font-medium uppercase text-[#FFE7B3]">
                  Xác nhận tham gia giải đấu
                </p>
                <div className="font-exo text-[#FFCA16] text-[14px]">
                  Ban đang tham gia vào giải đấu {league.name}. Vui lòng xác
                  nhận!
                </div>
              </div>
              <div className="flex">
                <button
                  onClick={(event) => joinLeague(event)}
                  className="block w-1/2 h-12 bg-[#0EB39E] text-center uppercase text-lg rounded border-2 border-[#ADF0DD] button-custom-shadow mt-6 mr-2 text-white"
                >
                  {" "}
                  {"Xác nhận"}
                </button>
                <button
                  onClick={() => setShowModal(false)}
                  className="block w-1/2 h-12 text-center uppercase text-lg rounded text-gray-11 border-[#B0B4BA] border-2 mt-6 mr-2"
                >
                  {" "}
                  {"Để sau"}
                </button>
              </div>
            </TEModalBody>
          </TEModalContent>
        </TEModalDialog>
      </TEModal>
    </>
  );
};

export default LeagueListingItem;
