import { child, push, ref, update } from "firebase/database";
import * as _ from "lodash";
import moment from "moment";
import "moment/locale/vi";
import { useEffect, useReducer, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import Navbar from "../../components/navbar";
import realtimeDB from "../../helpers/firebase";
import * as SBService from "../../helpers/sportbookService";
import { getLeagueById } from "../../helpers/sportbookService";
import { getAssetFullUrl } from "../../helpers/utils";
import {
  errorNotificationButtonContentState,
  errorNotificationContentState,
  errorNotificationTitleState,
  loaderState,
  showErrorNotificationState,
  userState,
} from "../../states/common";

const LeagueMatchingPage = () => {
  const [user, setUser] = useRecoilState(userState);
  const [player, setPlayer] = useState<any>();
  const [league, setLeague] = useState({
    id: "",
    name: "",
    type: "",
    rivalChallengeCoolDownTimeInDays: 0,
    maxMatchesPerWeek: 0,
  });
  const [, setLoader] = useRecoilState(loaderState);
  const navigate = useNavigate();

  const { leagueId, playerId, matchId } = useParams();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  let [players, setPlayers] = useState<any>([]);
  const [competitor, setCompetitor] = useState<any>([]);
  const [currentMatch, setCurrentMatch] = useState<any>({});
  const [playerRankScore, setPlayerRankScore] = useState<any>({});
  const [notCooldown, setNotcooldown] = useState(false);

  const [, setShowErrorModal] = useRecoilState(showErrorNotificationState);
  const [, setShowErrorContent] = useRecoilState(errorNotificationContentState);
  const [, setShowErrorTitle] = useRecoilState(errorNotificationTitleState);
  const [, setShowErrorButtonContent] = useRecoilState(
    errorNotificationButtonContentState
  );

  const [disableAction, setDisableAction] = useState(false);

  useEffect(() => {
    document.title = "Thông tin trận đấu";
    setLoader(true);
    const loadData = async function () {
      const rspLeague = await getLeagueById(leagueId);
      if (rspLeague) {
        setLeague(rspLeague.data);
      }
      await loadCompetitionData();
      await loadPlayers();
      if (matchId) {
        await getMatchDetail(matchId);
      }
      forceUpdate();
    };

    loadData();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(currentMatch) && matchId) {
      const loadData = async function () {
        await loadPlayers(1);
        forceUpdate();
      };
    }
  }, [currentMatch]);

  const getMatchDetail = async (matchFindId: string) => {
    try {
      if (matchFindId) {
        let userInfo: any;
        if (!user.id) {
          const userInfoTxt = localStorage.getItem("userInfo");
          if (userInfoTxt) {
            userInfo = JSON.parse(userInfoTxt);
          }
        } else {
          userInfo = user;
        }
        const rsp = await SBService.getMatchById(matchFindId);
        if (rsp.data) {
          if (rsp.data.scores) {
            if (userInfo?.id == rsp.data.challengerId) {
              setPlayerRankScore(rsp.data.scores.challenger);
            } else {
              setPlayerRankScore(rsp.data.scores.acceptor);
            }
          }
          setCurrentMatch(rsp.data);
        }
      }
    } catch (e: any) {
      if (e.response && e.response.data && e.response.data.error) {
        switch (e.response.data.error) {
          case "playersNotFoundInLeague":
            toast.error("Lỗi, Không tìm thấy đối thủ trong giải đấu");
            break;
          default:
            toast.error("Lỗi, " + e.response.data.error);
        }
      } else {
        toast.error("Không tìm thấy đối thủ trong giải đấu");
      }
    }
    setLoader(false);
  };

  const sendNotificationToUser = (id = "") => {
    const listenPath = `notifications/${id ? id : competitor.player.id}`;
    const messagesRef = ref(realtimeDB, listenPath);
    const newPostKey = push(child(ref(realtimeDB), "posts")).key;
    let newData: any = {};
    newData = {
      content: "thách đấu",
      challengerId: player.player.id,
      leagueId: leagueId,
      isRead: false,
      matchId: currentMatch.id,
      time: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
    const updates = { ["/" + newPostKey]: newData };

    // Ghi đè dữ liệu trong nút 'messages' bằng dữ liệu mới
    update(messagesRef, newData)
      .then(() => {
        console.log("Data added successfully!");
      })
      .catch((error) => {
        console.error("Error adding data: ", error);
      });
  };

  const loadCompetitionData = async () => {
    try {
      let leagueData;
      const rsp = await SBService.getLeagueById(leagueId);
      leagueData = rsp;
      if (rsp.data) leagueData = rsp.data;
    } catch (e: any) {
      if (e.response && e.response.data && e.response.data.error) {
        switch (e.response.data.error) {
          default:
            toast.error("Lỗi, " + e.response.data.error);
            break;
        }
      } else {
        toast.error("Không thể tìm giải đấu");
      }
      navigate(-1);
    }
    setLoader(false);
  };

  let [currentPage, setCurrentPage] = useState(1);
  let [isPlayerNextPage, setIsPlayerNextPage] = useState(false);

  const loadPlayers = async (page: number = 1) => {
    try {
      const leaguePlayers = await SBService.getLeaguePlayersById(
        leagueId,
        50,
        page
      );
      if (leaguePlayers.data) {
        let competitorData: any;
        let playerData: any;
        playerData = leaguePlayers.data.find(
          (pl: any) => pl.player.id === user.id
        );
        if (playerData) {
          setPlayer(playerData);
        }
        competitorData = leaguePlayers.data.find(
          (pl: any) => pl.player.id === playerId
        );
        if (competitorData) {
          setCompetitor(competitorData);
          if (!competitorData.player.ranking.class) {
            toast.error(
              `Vận động viên ${competitorData.player.name} chưa được khảo hạch. Không thể thách đấu`
            );
            navigate("/");
          }
        }
        if (page === 1) {
          setPlayers(leaguePlayers.data);
        } else {
          setCurrentPage(page);
          setPlayers(players.concat(leaguePlayers.data));
        }
        if (leaguePlayers.hasNextPage) {
          setIsPlayerNextPage(leaguePlayers.hasNextPage);
        }
      }
    } catch (e: any) {
      if (e.response && e.response.data && e.response.data.error) {
        switch (e.response.data.error) {
          default:
            toast.error("Lỗi, " + e.response.data.error);
            break;
        }
      } else {
        toast.error("Không thể tải danh sách cơ thủ tham gia giải đấu");
      }
    }
  };

  useEffect(() => {
    if (isPlayerNextPage) {
      loadPlayers(currentPage + 1);
    } else {
      if (!_.isEmpty(competitor)) {
        toast.error("Đối thủ chưa tham gia giải đấu!");
        navigate("/");
      }
    }
  }, [currentPage, isPlayerNextPage]);

  let [vsMatches, setVsMatches] = useState<any>([]);

  const getLeagueMatches = async () => {
    if (leagueId && playerId) {
      try {
        const rspPlayer = await SBService.getPlayerInLeagueData(
          leagueId,
          playerId
        );
        if (rspPlayer && rspPlayer.data && rspPlayer.data.playedMatches) {
          rspPlayer.data.playedMatches.forEach((match: any) => {
            if (match.challengerId === user.id) {
              match.challenger = user;
              match.acceptor = rspPlayer.data.player;
            } else {
              match.challenger = rspPlayer.data.player;
              match.acceptor = user;
            }
          });
          setVsMatches(rspPlayer.data.playedMatches);
        }
        const playerPlayedInWeek =
          await SBService.getMatchCountInLeagueByCurrentWeek(leagueId, user.id);
        let playedInWeek = 0;
        if (playerPlayedInWeek && playerPlayedInWeek.data) {
          playedInWeek = playerPlayedInWeek.data.total;
        }
        const competitorPlayedInWeekRsp =
          await SBService.getMatchCountInLeagueByCurrentWeek(
            leagueId,
            playerId
          );
        let competitorPlayedInWeek = 0;
        if (competitorPlayedInWeekRsp && competitorPlayedInWeekRsp.data) {
          competitorPlayedInWeek = competitorPlayedInWeekRsp.data.total;
        }

        if (!matchId) {
          if (
              league.maxMatchesPerWeek && (
            competitorPlayedInWeek >= league.maxMatchesPerWeek ||
            playedInWeek >= league.maxMatchesPerWeek
              )
          ) {
            setShowGlobalError(
              "Số trận đấu bạn tham gia tuần này đã đạt giới hạn. Vui lòng thi đấu tiếp vào tuần sau (bắt đầu từ 00:00 Thứ hai tuần sau).",
              "ĐÃ THI ĐẤU HẾT SỐ TRẬN TRONG TUẦN"
            );
          } else {
            await handleMakeDaftChallenger(leagueId, playerId);
          }
          setDisableAction(true);
        }
      } catch (e: any) {
        if (e.response && e.response.data && e.response.data.error) {
          switch (e.response.data.error) {
            case "AcceptorPlayingInAnotherMatch":
              toast.error(
                "Lỗi: Bạn đang trong 1 trận khác, không thể chấp nhận trận mới trước khi kết thúc "
              );
              break;
            default:
              toast.error("Lỗi, " + e.response.data.error);
              break;
          }
        } else {
          toast.error("Không thể tải danh sách các trận thách đấu");
        }
      }
    }
    forceUpdate();
  };

  const handleMakeDaftChallenger = async (
    leagueId: string,
    playerId: string
  ) => {
    try {
      const rsp: any = await SBService.makeDraftMatch(leagueId, playerId);
      if (rsp) {
        await getMatchDetail(rsp.data.id);
      }
    } catch (e: any) {
      if (e.response && e.response.data && e.response.data.error) {
        switch (e.response.data.error) {
          case "fullNumberOfMatches":
            toast.error("Lỗi, Số trận đấu của 2 bên đã đủ");
            break;
          case "waitingAccepted":
            toast.error("Lỗi, Đang chờ chấp nhận thách đấu");
            break;
          case "havingAnotherMatchWaitingConfirm":
            toast.error("2 bên có một trận đấu khác đang chờ xác nhận kết quả");
            break;
          case "notCoolDown":
            setNotcooldown(true);
            break;
          default:
            toast.error("Lỗi, " + e.response.data.error);
            break;
        }
      } else {
        toast.error("Không thể tạo trận thách đấu");
      }
    }
  };

  const handleAcceptChallenger = async () => {
    try {
      await SBService.acceptChallenger(currentMatch.id);
      navigate("/");
    } catch (e: any) {
      if (e.response && e.response.data && e.response.data.error) {
        if (e.response.data.error.includes("AcceptorPlayingInAnotherMatch")) {
          setShowGlobalError(
            "Bạn đang tham gia thi đấu. Vui lòng hoàn tất trận đấu hiện tại trước khí bắt đầu trận đấu mới."
          );
        } else if (
          e.response.data.error.includes("ChallengerPlayingInAnotherMatch")
        ) {
          setShowGlobalError(
            "Người thách đấu bạn đang trong 1 trận khác, không thể chấp nhận trận mới"
          );
        } else if (e.response.data.error.includes("leagueNotStart")) {
          setShowGlobalError("Giải đấu chưa bắt đầu. Vui lòng quay lại sau");
        } else {
          toast.error("Lỗi, " + e.response.data.error);
        }
      } else {
        toast.error("Không thể chấp nhận trận thách đấu");
      }
    }
    forceUpdate();
  };

  const handleDenyChallenger = async () => {
    try {
      await SBService.denyChallenger(currentMatch.id);
      navigate("/");
    } catch (e: any) {
      if (e.response && e.response.data && e.response.data.error) {
        switch (e.response.data.error) {
          default:
            toast.error("Lỗi, " + e.response.data.error);
            break;
        }
      } else {
        toast.error("Không thể từ chối trận thách đấu");
      }
    }
    forceUpdate();
  };

  useEffect(() => {
    const loadMatchData = async () => {
      await getLeagueMatches();
    };
    if (league.id) {
      loadMatchData();
    }
  }, [league]);

  const challengerCompetitor = async () => {
    try {
      if (currentMatch && currentMatch.id && currentMatch.status === "DRAFT")
        await SBService.makeChallenger(currentMatch.id);
      sendNotificationToUser();
      navigate("/");
    } catch (e: any) {
      if (e.response && e.response.data && e.response.data.error) {
        switch (e.response.data.error) {
          default:
            toast.error("Lỗi, " + e.response.data.error);
            break;
        }
      } else {
        toast.error("Không thể thách đấu đối thủ");
      }
    }
    forceUpdate();
  };

  const handleSubmitMatch = () => {
    return navigate(`/league/${leagueId}/match/${currentMatch.id}`);
  };

  const setShowGlobalError = (
    message: string,
    title: string = "",
    buttonText = ""
  ) => {
    if (title) {
      setShowErrorTitle(title);
    }
    if (buttonText) {
      setShowErrorButtonContent(buttonText);
    }
    setShowErrorContent(message);
    setShowErrorModal(true);
  };

  return (
    <div className="py-6">
      <Navbar title={"THÔNG TIN TRẬN ĐẤU"} />
      <div className="flex place-content-between">
        <h1 className="heading-8 text-2xl font-medium uppercase text-[--dark-neutral-gray-12] mb-4">
          {league.name}
        </h1>
        {currentMatch?.acceptor?.id === user.id && (
          <a
            className="uppercase text-[#0BD8B6]"
            onClick={() => handleDenyChallenger()}
          >
            Huỷ
          </a>
        )}
      </div>
      <div className="w-full bg-[#1E1E32] border-none rounded-[0.6rem] py-4">
        <div className="flex items-start space-x-4">
          <div className="flex-shrink-0">
            <img
              className="w-[96px] h-[96px] object-cover object-center rounded-full"
              src={getAssetFullUrl(competitor?.player?.profilePicture)}
              alt=""
            />
          </div>
          <div className="min-w-0 mt-4">
            <p className="text-lg font-medium truncate">
              {competitor?.player?.name}
            </p>
            <div className="truncate mt-3 text-white opacity-65 flex w-full">
              <div className="flex items-center justify-center">
                <label className="text-gray-11 uppercase">Xếp hạng</label>
                <div className="text-white ml-[6px] flex flex-wrap">
                  {competitor.player ? (
                    <div>
                      <span className="inline-block text-center w-full">
                        {competitor.player.ranking?.class}
                      </span>
                    </div>
                  ) : (
                    "--"
                  )}
                </div>
              </div>
              <div className=" flex items-center justify-center ml-5">
                <label className="text-gray-11 uppercase">Điểm</label>
                <span className="text-white ml-[6px]">
                  {competitor?.player?.score}
                </span>
              </div>
            </div>
          </div>
        </div>

        {!_.isEmpty(vsMatches) ? (
          <div>
            <p className="text-bold text-center font-exo">
              Lịch sử đối đầu{" "}
              <span
                className={
                  vsMatches.length < 2 ? "text-[#0BD8B6]" : "text-[#FFCA16]"
                }
              >
                ({vsMatches.length}/2)
              </span>
            </p>
            {vsMatches.map((mt: any, index: number) => (
              <div className="py-2" key={`old-matches-${index}`}>
                <div className="w-full flex font-exo text-gray-11 text-sm">
                  <div className="w-1/2 flex justify-end  items-center px-2">
                    <span className="px-2">{mt.acceptor?.name}</span>{" "}
                    <img
                      className="w-6 h-6 rounded-full text-center"
                      src={getAssetFullUrl(mt.acceptor?.profilePicture)}
                    />{" "}
                    <span className="px-2 font-bold">{mt.acceptorPoint}</span>
                  </div>
                  <div className="w-1/2 flex justify-start items-center">
                    <span className="px-2 font-bold">{mt.challengerPoint}</span>{" "}
                    <img
                      className="w-6 h-6 rounded-full  text-center"
                      src={getAssetFullUrl(mt.challenger?.profilePicture)}
                    />{" "}
                    <span className="px-2">{mt.challenger?.name}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          ""
        )}
      </div>

      {notCooldown ? (
        <div>
          <div className="w-full text-left border border-dotted rounded-[0.5rem] py-2 px-4">
            <p className="font-exo text-sm font-medium uppercase text-[#FFE7B3]">
              CHƯA HẾT THỜI GIAN CHỜ
            </p>
            <p className="font-exo text-[#FFCA16] text-[14px]">
              Bạn đã thi đấu với vận động viên này trong{" "}
              {league.rivalChallengeCoolDownTimeInDays} ngày qua. Không thể tiếp
              tục thi đấu!
            </p>
          </div>
          {league.id && vsMatches.length > 0 ? (
            <>
              <p className="inline-block w-full text-center mt-2 text-gray-11">
                Có thể thi đấu:{" "}
                {moment(vsMatches[0].endedAt)
                  .add(league.rivalChallengeCoolDownTimeInDays, "days")
                  .format("HH:mm, DD [tháng] MM, YYYY ")}
              </p>
            </>
          ) : (
            <></>
          )}
          <div>
            <button
              onClick={() => navigate(-1)}
              className="block w-full h-12 bg-[#0EB39E] text-center uppercase text-sm rounded border-2 border-[#ADF0DD] button-custom-shadow mt-6 mr-2"
            >
              Thi đấu sau
            </button>
          </div>
        </div>
      ) : (
        <>
          {vsMatches.length < 2 && currentMatch && currentMatch?.race > 0 ? (
            <div>
              <div className="mt-4 rounded w-full px-4 py-2 bg-[#0D1514] border border-[#084843]">
                <h3 className="text-[#0BD8B6] uppercase font-medium text-sm font-exo">
                  Thể thức thi đấu
                </h3>
                <p className="text-[#FFCA16] font-exo text-sm font-medium">
                  Chạm {currentMatch?.race} để thắng, { league.type !== 'ELIMINATION' ? <>({currentMatch?.race - 1}-
                  {currentMatch?.race - 1} hòa) </> : <></> }
                </p>
                {currentMatch.handicappingPoint > 0 ? (
                  <p className="text-[#FFCA16] font-exo text-sm font-medium">
                    <span className="text-[#0BD8B6]">
                      {currentMatch.challenger.name}
                    </span>{" "}
                    chấp{" "}
                    <span className="text-[#0BD8B6]">
                      {currentMatch.acceptor.name}
                    </span>{" "}
                    {Math.abs(currentMatch.handicappingPoint)} ván
                  </p>
                ) : (
                  <p className="text-[#FFCA16] font-exo text-sm font-medium">
                    <span className="text-[#0BD8B6]">
                      {currentMatch.acceptor.name}
                    </span>{" "}
                    chấp{" "}
                    <span className="text-[#0BD8B6]">
                      {currentMatch?.challenger?.name}
                    </span>{" "}
                    {Math.abs(currentMatch.handicappingPoint)} ván
                  </p>
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          {vsMatches.length >= 2 && (
            <div className="h-[30vh]">
              <div className="mt-4 rounded w-full px-4 py-2 bg-[#0D1514] border border-[#084843]">
                <h3 className="text-[#FFE7B3] uppercase font-medium text-sm font-exo">
                  KHÔNG THỂ BẮT ĐẦU TRẬN ĐẤU
                </h3>
                <p className="text-[#FFCA16] font-exo text-sm font-medium">
                  Hai bạn đã thi đấu 2/2 trận trong giải{" "}
                  {league.name ? league.name : ""}. Vui lòng chọn đối thủ khác
                </p>
              </div>
            </div>
          )}

          {vsMatches.length < 2 && (
            <div className="w-full px-2 mt-6">
              <table className="table table-auto w-full font-exo text-xs text-center">
                <thead className="border-b border-[#5E5E5E2B]">
                  <tr className="text-sm">
                    <th className="w-4/12"></th>
                    {player ? (
                      <th className="w-4/12 h-[52px] ">
                        <div className="w-full flex items-center justify-center">
                          <img
                            className="w-6 h-6 rounded-full text-center float-left"
                            src={getAssetFullUrl(
                              player?.player?.profilePicture
                            )}
                          />{" "}
                          <span className="float-left ml-1 mt-1">
                            {player?.player?.name}
                          </span>
                        </div>
                      </th>
                    ) : (
                      <th></th>
                    )}
                    {competitor ? (
                      <th className="w-4/12 h-[52px]">
                        <div className="w-full flex items-center justify-center">
                          <img
                            className="w-6 h-6 rounded-full  text-center float-left"
                            src={getAssetFullUrl(
                              competitor?.player?.profilePicture
                            )}
                          />{" "}
                          <span className="float-left ml-1 mt-1">
                            {competitor?.player?.name}
                          </span>
                        </div>
                      </th>
                    ) : (
                      <th></th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b border-[#5E5E5E2B]">
                    <td className="py-4">ĐIỂM XH</td>
                    <td>{player?.player?.score}</td>
                    <td>{competitor?.player?.score}</td>
                  </tr>
                  <tr className="border-b border-[#5E5E5E2B]">
                    <td className="py-4 uppercase">Trình độ</td>
                    <td>
                      {player?.player?.ranking
                        ? player.player.ranking.class
                        : "--"}
                    </td>
                    <td>
                      {competitor?.player?.ranking
                        ? competitor.player.ranking.class
                        : "--"}
                    </td>
                  </tr>
                  <tr className="border-b border-[#5E5E5E2B] hidden">
                    <td className="py-4 uppercase">
                      Thứ hạng <br />
                      trong giải
                    </td>
                    <td>
                      {player?.ranking > 0 ? player.ranking : "--"}/
                      {players.length}
                    </td>
                    <td>
                      {competitor?.ranking}/{players.length}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {/* <div className="w-full px-4 mt-6 bg-[#1E1E32] rounded-[0.6rem] flex items-center place-content-between py-4">
            <h1 className="heading-8 text-xl font-medium uppercase text-[--dark-neutral-gray-12]">thi đấu tại bàn</h1>
            <input type="number" className="w-[88px] h-[58px] mr-1 bg-main rounded text-center text-[30px] border border-[#084843]" defaultValue={13} />
        </div> */}

          {vsMatches.length < 2 && (
            <div className="w-full px-2 mt-6 bg-[#1E1E32] rounded-[0.6rem] py-4">
              <h3 className="text-center text-[--dark-neutral-gray-11] font-medium heading-6 uppercase">
                Điểm sẽ nhận được từ trận đấu
              </h3>

              <table className="table table-auto w-full font-exo text-xs mt-4">
                <thead className="border-b border-[#5E5E5E2B]">
                  <tr className="text-sm uppercase">
                    <th></th>
                    <th>Thắng</th>
                    <th className="py-4">Thua</th>
                    {league.type !== 'ELIMINATION' ? <th>Hòa</th> : <></>}

                  </tr>
                </thead>
                <tbody>
                {league.type !== 'ELIMINATION' ? <tr className="border-b border-[#5E5E5E2B]">
                      <td className="py-4">Điểm giải</td>
                      <td className="text-center">+3</td>
                      <td className="text-center">0</td>
                      <td className="text-center">1</td>
                    </tr>
                    : <></>
                }

                {playerRankScore ? (
                    <tr className="">
                      <td className="py-4">Điểm xếp hạng</td>
                      <td className="text-center">+{playerRankScore.win}</td>
                      <td className="text-center">-{playerRankScore.loss}</td>
                      {league.type !== 'ELIMINATION' ?
                            <td className="text-center">+{playerRankScore.draw}</td> : <></>
                      }

                    </tr>
                  ) : (
                    <tr className="">
                      <td className="py-4">Điểm xếp hạng</td>
                      <td className="text-center">+5</td>
                      <td className="text-center">0</td>
                      {league.type !== 'ELIMINATION' ?
                      <td className="text-center">0</td> : <></>
                      }
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
          {player?.player?.ranking?.class &&
            competitor?.player?.ranking?.class &&
            vsMatches.length < 2 && (
              <div>
                {currentMatch.status === "WAITING" &&
                currentMatch.acceptor.id === user.id &&
                !disableAction ? (
                  <div className="w-full flex">
                    <button
                      onClick={() => handleAcceptChallenger()}
                      className="block w-1/2 h-12 bg-[#0EB39E] text-center uppercase text-sm rounded border-2 border-[#ADF0DD] button-custom-shadow mt-6 mr-2"
                    >
                      Xác nhận
                    </button>
                    <button
                      onClick={() => handleDenyChallenger()}
                      className="block w-1/2 h-12 bg-[#1E1E32] text-center uppercase text-sm rounded border-2 border-[#3B3B47] mt-6 text-gray-11 ml-2"
                    >
                      Từ chối
                    </button>
                  </div>
                ) : currentMatch.status === "WAITING" &&
                  currentMatch.acceptor.id === competitor.player.id ? (
                  <button className="block w-full h-12 bg-[#0EB39E] text-center uppercase text-sm rounded border-2 border-[#ADF0DD] button-custom-shadow mt-6">
                    <span className="opacity-40">
                      Đang đợi đối thủ xác nhận...
                    </span>
                  </button>
                ) : currentMatch.status === "DRAFT" &&
                  currentMatch.challenger.id === user.id ? (
                  <button
                    className="block w-full h-12 bg-[#0EB39E] text-center uppercase text-sm rounded border-2 border-[#ADF0DD] button-custom-shadow mt-6"
                    onClick={() => challengerCompetitor()}
                  >
                    THÁCH ĐẤU{" "}
                  </button>
                ) : (
                  ""
                )}
                {currentMatch.status === "ACCEPTED" && (
                  <button
                    onClick={() => handleSubmitMatch()}
                    className="block w-full h-12 bg-[#F3D768] text-center uppercase text-medium rounded border-2 border-[#F3D768] button-info-custom-shadow mt-6 text-[#473B1F]"
                  >
                    Cập nhật kết quả
                  </button>
                )}
              </div>
            )}

          {vsMatches.length >= 2 && (
            <button
              onClick={() => navigate("/")}
              className="block w-full h-12 bg-[#0EB39E] text-center uppercase text-sm rounded border-2 border-[#ADF0DD] button-custom-shadow mt-6"
            >
              Quay về trang chủ
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default LeagueMatchingPage;
