import Icon from "components/icon";
import CompetitorCard from "components/league/CompetitorCard";
import RankUserCard from "components/league/RankUserCard";
import * as _ from "lodash";
import moment, { ISO_8601 } from "moment";
import { useEffect, useReducer, useState } from "react";
import toast from "react-hot-toast";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import leageRankImage from "statics/images/leage-rank-img.png";
import {
  TEModal,
  TEModalBody,
  TEModalContent,
  TEModalDialog,
  TETabs,
  TETabsContent,
  TETabsItem,
  TETabsPane,
} from "tw-elements-react";
import CompetitorCheckinCard from "../../components/league/CompetitorCheckinCard";
import PlayerLeagueProgress from "../../components/league/PlayerLeagueProgress";
import Navbar from "../../components/navbar";
import * as SBService from "../../helpers/sportbookService";
import LeagueInterface, { defaultLeague } from "../../interfaces/league";
import { bottomBarState, loaderState, userState } from "../../states/common";

const LeagueStandingPage = () => {
  const [user, setUser] = useRecoilState(userState);
  const [active, setActive] = useRecoilState(bottomBarState);
  let [players, setPlayers] = useState<any>([]);
  let [userInLeague, setUserInLeague] = useState<any>({});
  let [playerCheckin, setPlayerCheckin] = useState<any>({});
  let [currentTime, setCurrentTime] = useState<any>(moment());
  const [, setLoader] = useRecoilState(loaderState);
  const { id } = useParams();
  const navigate = useNavigate();

  const [totalWeek, setTotalWeek] = useState<any>(0);
  const [currentWeek, setCurrentWeek] = useState<any>(0);
  const [currentViewWeek, setCurrentViewWeek] = useState<any>(0);
  const [isCompleteMinRequired, setIsCompleteMinRequired] =
    useState<boolean>(false);
  const [totalComplete, setTotalComplete] = useState<number>(0);

  const [basicActive, setBasicActive] = useState("tab1");
  const handleBasicClick = (value: string) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };

  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [address, setAddress] = useState("");
  const [league, setLeague] = useState<LeagueInterface>(defaultLeague);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setActive("league");
    const loadData = async function () {
      setLoader(true);
      getLeagueData();
      setLoader(false);
    };
    loadData();
  }, []);

  const getLeagueData = async () => {
    try {
      let leagueData;
      const rsp = await SBService.getLeagueById(id);
      leagueData = rsp;
      if (rsp.data) {
        leagueData = rsp.data;
      }
      if (leagueData) {
        if (leagueData.startDate) {
          leagueData.startDateObj = moment(leagueData.startDate, ISO_8601);
          leagueData.endDateObj = moment(leagueData.endDate, ISO_8601);
          leagueData.startRegistrationDateObj = moment(
            leagueData.startRegistrationDate,
            ISO_8601
          );
          leagueData.endRegistrationDateObj = moment(
            leagueData.endRegistrationDate,
            ISO_8601
          );
          const totalWeekCalculated = Math.ceil(
            leagueData.endDateObj.diff(leagueData.startDateObj, "days") / 7
          );
          setTotalWeek(totalWeekCalculated);
          const diffDateCurrent = currentTime.diff(
            leagueData.startDateObj,
            "days"
          );
          if (diffDateCurrent < 0) {
            setCurrentWeek(0);
            setCurrentViewWeek(0);
          } else if (diffDateCurrent === 0) {
            setCurrentWeek(1);
            setCurrentViewWeek(1);
          } else {
            const week = Math.ceil((diffDateCurrent + 1) / 7);
            setCurrentWeek(week);
            if (week > totalWeekCalculated) {
              setCurrentViewWeek(totalWeekCalculated);
            } else {
              setCurrentViewWeek(week);
            }
          }
        }
        setLeague(leagueData);
        await loadPlayers();
        await loadCheckedInPlayer();
      }
      if (leagueData.venues) {
        setAddress(leagueData.venues.map((venue: any) => venue.name).join(","));
      }
    } catch (e: any) {
      navigate(-1);
    }
    forceUpdate();
  };

  let [, setCurrentPage] = useState(1);
  let [, setIsPlayerNextPage] = useState(true);

  const loadPlayers = async (page: number = 1) => {
    const leaguePlayers = await SBService.getLeaguePlayersById(id, 50, page);
    if (leaguePlayers.data) {
      if (page === 1) {
        players = leaguePlayers.data;
      } else {
        players = players.concat(leaguePlayers.data);
      }
      const userRec = await SBService.getPlayerInLeagueData(id, user.id);
      if (userRec.data && userRec.data.leaguePlayerWeek.length > 0) {
        let totalComplete = 0;
        try {
          userRec.data.leaguePlayerWeek.forEach((week: any) => {
            if (week.isClaimedMinRequiredMatch) totalComplete++;
          });
        } catch (e: any) {
          console.log(e);
        }
        setTotalComplete(totalComplete);
      }
      setUserInLeague(userRec.data);
      setPlayers(players);
      if (leaguePlayers.hasNextPage) {
        setCurrentPage(leaguePlayers.page);
        setIsPlayerNextPage(leaguePlayers.hasNextPage);
      }
    }
    forceUpdate();
  };

  const loadCheckedInPlayer = async (page = 1) => {
    const leaguePlayers = await SBService.getCheckinListByLeagueId(
      id,
      50,
      page
    );
    if (leaguePlayers.data) {
      if (page === 1) {
        playerCheckin = leaguePlayers.data;
      } else {
        playerCheckin = playerCheckin.concat(playerCheckin.data);
      }
      setPlayerCheckin(playerCheckin);
    }
  };

  const updateIndexOfViewWeek = (index: number) => {
    setCurrentViewWeek(index + 1);
  };

  const renderWeekListing = () => {
    return [...Array(totalWeek)].map((x, i) => (
      <PlayerLeagueProgress
        key={`league-progress-${i}`}
        league={league}
        playerLeagueWeek={userInLeague.leaguePlayerWeek.find(
          (lpWeek: any) => lpWeek.week === i + 1
        )}
        startOfWeek={league.startDateObj.clone().add(i, "week").format("DD/MM")}
        endOfWeek={league.startDateObj
          .clone()
          .add(i + 1, "week")
          .subtract(1, "day")
          .format("DD/MM")}
        state={
          i + 1 < currentWeek
            ? "pass"
            : i + 1 === currentWeek
            ? "running"
            : "waiting"
        }
      />
    ));
  };

  const hideGlobalShowModel = () => {
    setShowModal(false);
  };

  const leaveLeague = async (leagueId: string, userId: string) => {
    try {
      await SBService.leaveLeague(leagueId, userId);
      setUserInLeague(null);
      toast.success("Rời khỏi giải thành công");
      window.location.reload();
      setUser((prevUser) => {
        const updatedLeagues = prevUser.leagues.filter(
          (league) => league.id !== leagueId
        );
        return { ...prevUser, leagues: updatedLeagues };
      });
    } catch (e: any) {
      toast.error("Rời khỏi giải thất bại");
    }
  };

  useEffect(() => {
    if (league.id && totalComplete) {
      if (totalComplete > league.minWeekReachMinMatchesRequired) {
        setIsCompleteMinRequired(true);
      }
    }
  }, [league, totalComplete]);

  const checkIn = async () => {
    setLoader(true);
    try {
      await SBService.checkinLeagueById(league.id);
      setUserInLeague({ ...userInLeague, ...{ isCheckin: true } });
      toast.success("Check-In thành công");
      await loadCheckedInPlayer(1);
    } catch (e: any) {
      toast.error("Check-In thất bại");
    }
    setLoader(false);
  };

  const checkOut = async () => {
    setLoader(true);
    try {
      await SBService.checkoutLeagueById(league.id);
      setUserInLeague({ ...userInLeague, ...{ isCheckin: false } });
      toast.success("Check-out thành công");
      await loadCheckedInPlayer(1);
    } catch (e: any) {
      toast.error("Check-out thất bại");
    }
    setLoader(false);
  };

  return (
    <div className="h-fit overflow-hidden">
      <Navbar title="Thông tin giải đấu" />
      <div className="mt-3">
        <h1 className="text-[30px] text-gray-12">
          {league.name}{" "}
          <a
            className="inline-block text-2xl text-accent -rotate-45"
            onClick={() => navigate("/league/" + league.id)}
          >
            <Icon icon={"arrow-right"} />
          </a>
        </h1>
      </div>

      <span className="text-gray-11 font-normal font-exo">
        {league.startDateObj.format("DD MMMM")} -{" "}
        {league.endDateObj.format("DD MMMM")}
      </span>

      <div className="relative my-2 font-exo hidden">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg
            className="w-4 h-4 text-gray-500"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
            />
          </svg>
        </div>
        <input
          type="search"
          id="default-search"
          className="block w-full py-2  pl-10 pr-3 text-sm rounded-lg dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white bg-[#1E1E32] text-white"
          placeholder="Tìm kiếm cơ thủ"
        />
      </div>

      {userInLeague && league.startDateObj.diff(currentTime) <= 0 ? (
        <>
          <div className="bg-[#5A6169] flex p-2 rounded-[0.5rem] overflow-hidden relative mt-4 z-0 bg-[#FF5821]">
            <div className="w-full relative z-10 ">
              <RankUserCard
                player={userInLeague}
                index={userInLeague.leaguePosition}
              />
            </div>
            <img
              className="absolute right-0 top-0 z-0 mix-blend-multiply h-full"
              src={leageRankImage}
            />
          </div>
        </>
      ) : (
        <>
          <div className="bg-[#5A6169] flex px-2 py-6 rounded-[0.5rem] overflow-hidden relative mt-4 z-3 bg-white text-2xl">
            <div className="w-full relative z-10 text-center">
              Giải đấu sắp diễn ra
            </div>
            <img
              className="absolute right-0 top-0 z-0 mix-blend-multiply h-full"
              src={leageRankImage}
            />
            <div className="absolute bg-black left-0 top-0 w-full h-full opacity-50"></div>
          </div>
        </>
      )}

      {currentWeek > 0 &&
      league &&
      league.id &&
      userInLeague &&
      userInLeague.id &&
      league.minMatchesPerWeek ? (
        <div className="rounded bg-[#1E1E32] mt-4 px-2 py-4 box-border flex flex-wrap">
          <div className="w-1/2 pl-4">
            <span className="uppercase opacity-70">tiến độ TUẦN</span>
          </div>
          <div className="w-1/2 text-right pr-4">
            <span className=" opacity-70">Tuần </span>
            <span>
              {currentViewWeek}/{totalWeek}
            </span>
          </div>
          <div className="wrapper-slider-week-progress w-full">
            <Carousel
              className="overflow-hidden w-full league-standing-carousel py-2"
              showIndicators={false}
              showStatus={false}
              showArrows={true}
              autoPlay={false}
              showThumbs={false}
              selectedItem={currentViewWeek - 1}
              onChange={(index, item) => updateIndexOfViewWeek(index)}
            >
              {renderWeekListing()}
            </Carousel>
            <div className="px-11 mt-2 font-exo font-bold">
              <span
                className={
                  isCompleteMinRequired
                    ? "text-[#0EB39E]  bg-[#EDEEF0] rounded-full"
                    : "text-[#B0B4BA]  bg-[#EDEEF0] rounded-full"
                }
              >
                <Icon size={"xl"} icon={"fa-circle-dot"} />
              </span>
              <span className="text-[#989898] ml-2">Đủ điều kiện nhận cọc</span>{" "}
              <span
                className="text-[#0EB39E] rounded-full border border-[#0EB39E] inline-block px-2"
                onClick={() => setShowModal(true)}
              >
                <Icon size={"xs"} icon={"fa-question"} />
              </span>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="league-content-tab rounded bg-[#1E1E32] w-full">
        <TETabs className="w-full">
          <TETabsItem
            wrapperClass={"w-1/2 bg-[#1E1E32]"}
            className="w-full py-3 customTab"
            onClick={() => handleBasicClick("tab1")}
            active={basicActive === "tab1"}
            tag="button"
            color="primary"
          >
            Bảng xếp hạng
          </TETabsItem>
          <TETabsItem
            wrapperClass={"w-1/2 bg-[#1E1E32]"}
            className="w-full py-3"
            onClick={() => handleBasicClick("tab2")}
            active={basicActive === "tab2"}
            tag="button"
            color="primary"
          >
            Đang thi đấu
          </TETabsItem>
        </TETabs>
        <TETabsContent className="text-[#B0B4BA]">
          <TETabsPane className="p-4 text-sm" show={basicActive === "tab1"}>
            <div className="flex">
              {league.startDateObj.diff(currentTime) > 0 ? (
                <>
                  <span className="uppercase text-sm opacity-70 w-[70%]">
                    Cơ thủ
                  </span>
                </>
              ) : (
                <>
                  <span className="uppercase text-sm opacity-70 w-[20%] pl-3">
                    #
                  </span>
                  <span className="uppercase text-sm opacity-70 w-[50%]">
                    Cơ thủ
                  </span>
                </>
              )}

              <span className="uppercase text-sm opacity-70 w-[10%]">Hạng</span>
              <span className="uppercase text-sm opacity-70 w-[20%]">
                Đối đầu
              </span>
            </div>
            <div className="league-player h-[calc(100vh/2)] overflow-auto">
              {!_.isEmpty(players)
                ? players.map((player: object, index: number) => {
                    if (!_.isEmpty(player))
                      return (
                        <CompetitorCard
                          key={`player-leagues-${index}`}
                          player={player}
                          index={index}
                          leagueId={league.id}
                          hideCountMatch={
                            league.startDateObj.diff(currentTime) > 0
                          }
                          listPlayer={players}
                        />
                      );
                  })
                : ""}
            </div>
            {/* <pre className="font-exo whitespace-pre-line leading-6 text-justify">{league.prize}</pre> */}
          </TETabsPane>
          <TETabsPane className="font-exo p-4" show={basicActive === "tab2"}>
            {userInLeague.isCheckin ? (
              <button
                className="btn w-full btn-primary text-white py-2 heading-6 uppercase"
                onClick={() => checkOut()}
              >
                Check-Out
              </button>
            ) : (
              <button
                className="btn w-full btn-primary text-white py-2 heading-6 uppercase"
                onClick={() => checkIn()}
              >
                Check-In
              </button>
            )}
            <div className="mt-4 text-sm">
              <div className="flex font-bold">
                {league.startDateObj.diff(currentTime) > 0 ? (
                  <>
                    <span className="uppercase text-sm opacity-70 w-[60%]">
                      Cơ thủ
                    </span>
                  </>
                ) : (
                  <>
                    <span className="uppercase text-sm opacity-70 w-[20%] pl-3">
                      #
                    </span>
                    <span className="uppercase text-sm opacity-70 w-[40%]">
                      Cơ thủ
                    </span>
                  </>
                )}

                <span className="uppercase text-sm opacity-70 w-[20%]">
                  Hạng
                </span>
                <span className="uppercase text-sm opacity-70 w-[20%]">
                  số trận
                </span>
              </div>
              <div className="league-player h-[calc(100vh/2)] overflow-auto">
                {!_.isEmpty(playerCheckin)
                  ? playerCheckin.map((player: object, index: number) => {
                      if (!_.isEmpty(player))
                        return (
                          <CompetitorCheckinCard
                            key={`player-checkin-leagues-${index}`}
                            player={player}
                            index={index}
                            leagueId={league.id}
                            listPlayer={playerCheckin}
                          />
                        );
                    })
                  : ""}
              </div>
            </div>
          </TETabsPane>
        </TETabsContent>
      </div>

      <div>
        <p className="uppercase mb-2 mt-6 font-medium"></p>
      </div>

      <TEModal className="zIndexTop" show={showModal} setShow={setShowModal}>
        <TEModalDialog className="h-full" centered={true}>
          <TEModalContent>
            <TEModalBody className="bg-[#1E1E32] text-center border border-[#084843] rounded">
              <div className="w-full text-left border border-dotted rounded-[0.5rem] py-2 px-4 border-[#4D3000] bg-[#16120C]">
                <p className="font-exo text-sm font-medium uppercase text-[#FFE7B3]">
                  ĐIỀU KIỆN NHẬN CỌC
                </p>
                <div className="font-exo text-[#FFCA16] text-[14px]">
                  Bạn cần tham gia đủ {league.minMatchesPerWeek} trận đấu mỗi
                  tuần trong {league.minWeekReachMinMatchesRequired} tuần để
                  nhận lại cọc.
                </div>
              </div>
              <div className="flex">
                <button
                  onClick={() => hideGlobalShowModel()}
                  className="block w-full h-12 bg-[#0EB39E] text-center uppercase text-lg rounded border-2 border-[#ADF0DD] button-custom-shadow mt-6 mr-2"
                >
                  {" "}
                  {"đã hiểu"}
                </button>
              </div>
            </TEModalBody>
          </TEModalContent>
        </TEModalDialog>
      </TEModal>

      {league && league.startDateObj.diff(currentTime) > 0 && userInLeague && (
        <div className="flex w-full">
          <button
            className="block w-full h-12 text-[#0bd8b6] text-center text-base rounded font-medium font-exo"
            onClick={() => leaveLeague(league.id, user.id)}
          >
            Rút khỏi giải
          </button>
        </div>
      )}
    </div>
  );
};

export default LeagueStandingPage;
