import * as SBService from "helpers/sportbookService";
import LeagueInterface, {
  BracketLeagueDetailInterface,
} from "interfaces/league";
import moment, { ISO_8601 } from "moment";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const acquireDate = (date: string): moment.Moment => {
  return moment(date, ISO_8601);
};

export const useLeagueDetail = (
  id: string,
  fallback: LeagueInterface
): LeagueInterface => {
  const [league, setLeague] = useState(fallback);

  const fetcher = async (id: string) => {
    let leagueData: LeagueInterface;

    const rsp = await SBService.getLeagueById(id);

    if (rsp.data) {
      leagueData = rsp.data;
    } else {
      leagueData = rsp;
    }

    if (!leagueData) throw Error("League not found");

    const { startDate, endDate, startRegistrationDate, endRegistrationDate, startPairingDate, endPairingDate } =
      leagueData;

    leagueData.startDateObj = acquireDate(startDate);
    leagueData.endDateObj = acquireDate(endDate);
    leagueData.startRegistrationDateObj = acquireDate(startRegistrationDate);
    leagueData.endRegistrationDateObj = acquireDate(endRegistrationDate);
    leagueData.startPairingDateObj = acquireDate(startPairingDate);
    leagueData.endPairingDateObj = acquireDate(endPairingDate);

    setLeague(leagueData);
  };

  useEffect(() => {
    (async () => fetcher(id))();
  }, [id]);

  return league;
};

export const useBracketLeagueDetail = (leagueId: string) => {
  const [league, setLeague] = useState<BracketLeagueDetailInterface>();

  const fetcher = async () => {
    try {
      const data = await SBService.getBracketLeagueInfo(leagueId);
      setLeague(data?.data);
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.error) {
        return toast.error("Lỗi, " + err.response.data.error);
      }

      return toast.error("Không thể tải thông tin cặp đấu loại trực tiếp");
    }
  };

  useEffect(() => {
    (async () => fetcher())();
  }, [leagueId]);

  return league;
};
