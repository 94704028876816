import { useLeagueDetail } from "hooks/league";
import { defaultLeague } from "interfaces/league";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import * as SBService from "../../helpers/sportbookService";
import { getAssetFullUrl } from "../../helpers/utils";
import { userState } from "../../states/common";
import logo from "statics/images/logo.png";

const ProfileJoinedLeague = (props: any) => {
  // @ts-ignore
  const { league } = props;
  const leagueExtra = useLeagueDetail(league?.id, defaultLeague);
  const user = useRecoilValue(userState);
  const startDateObj = moment(league.startDate);
  const endDateObj = moment(league.endDate);
  const navigate = useNavigate();
  let [player, setPlayer] = useState<any>([]);
  let [players, setPlayers] = useState<any>([]);
  let [currentPage, setCurrentPage] = useState(1);
  let [isPlayerNextPage, setIsPlayerNextPage] = useState(true);
  const [leagueMatches, setLeagueMatches] = useState([]);


  useEffect(() => {
    loadPlayers();
    getLeagueMatches();
  }, []);

  const loadPlayers = async (page: number = 1) => {
    const leaguePlayers = await SBService.getLeaguePlayersById(
      league.id,
      50,
      page
    );
    if (leaguePlayers.data) {
      leaguePlayers.data.map((pl: any, index: number) => {
        if (pl.player.id === user.id) {
          pl.position = index + 1;
          setPlayer(pl);
        }
      });

      if (page === 1) {
        players = leaguePlayers.data;
      } else {
        players = players.concat(leaguePlayers.data);
      }
      setPlayers(players);
      setCurrentPage(leaguePlayers.page);
      setIsPlayerNextPage(leaguePlayers.hasNextPage);
    }
  };

  useEffect(() => {
    if (isPlayerNextPage) {
      loadPlayers(currentPage + 1);
    }
  }, [isPlayerNextPage]);

  let [vsMatches, setVsMatches] = useState<any>([]);
  let [isMatchsNextPage, setIsMatchsNextPage] = useState(true);
  let [currentMatchsPage, setCurrentMatchsPage] = useState(1);

  const getLeagueMatches = async (page: number = 1) => {
    let matchesData: any = leagueMatches;
    const rspMatches = await SBService.getLeagueMatches(league.id, 50, page);
    if (rspMatches.data) {
      rspMatches.data.map((match: any) => {
        if (
          (match.acceptor.id === user.id || match.challenger.id === user.id) &&
          ["WAITING", "ACCEPTED", "SUBMITTED"].includes(match.status)
        ) {
          if (
            !(
              match.status === "SUBMITTED" &&
              match.isWaitingRivalConfirmed === false
            )
          ) {
            if (!vsMatches.find((oldMatch: any) => match.id === oldMatch.id)) {
              vsMatches.push(match);
            }
          }
        }
        matchesData.push(match);
      });

      setVsMatches(vsMatches);
      setLeagueMatches(matchesData);
      setCurrentMatchsPage(rspMatches.page);
      setIsMatchsNextPage(rspMatches.hasNextPage);
    }
  };

  useEffect(() => {
    if (isMatchsNextPage) {
      getLeagueMatches(currentMatchsPage + 1);
    }
  }, [currentMatchsPage, isMatchsNextPage]);

  const matchingWithAnotherPlayer = (competitorId: string, matchId: string) => {
    navigate(
      `/league/${league.id}/competitor/${competitorId}/match/${matchId}`
    );
  };

  const navigateToResultPage = (matchId: string) => {
    navigate(`/league/${league.id}/match/${matchId}/result`);
  };

  return (
    <div className="w-full bg-[#1E1E32] ">
      <div
        className="p-3 mt-6 flex rounded"
        onClick={(event) =>
          leagueExtra?.type === "ELIMINATION"
            ? navigate(`/league/${league.id}/detail/${user?.id}`)
            : navigate(`/league/${league.id}/detail`)
        }
      >
        <div>
          <img
            className="rounded w-[60px] h-[60px]"
            src={
              league.logo
                ? getAssetFullUrl(league.logo)
                : logo
            }
          />
        </div>
        <div className="ml-2 w-8/12">
          <h3 className="text-xl font-medium">{league.name}</h3>
          <p className="font-light font-exo text-sm color-[#B0B4BA] mt-2">
            {startDateObj.format("DD/MM/YY")} - {endDateObj.format("DD/MM/YY")}
          </p>
        </div>
        <div className="text-right w-2/12">
          <p className="font-light font-exo text-sm color-[#B0B4BA]">HẠNG</p>
          <h3 className="text-xl font-medium mt-2">
            {player.ranking > 0 ? player.position : "--"}/{players.length}
          </h3>
        </div>
      </div>

      {vsMatches
        ? vsMatches.map((match: any) => {
            if (["ACCEPTED", "WAITING", "SUBMITTED"].includes(match.status))
              return (
                <div className="p-2 flex rounded" key={match.id}>
                  <div>
                    <img
                      className="rounded-full w-[46px] h-[46px]"
                      src={
                        match.acceptor.id != user.id
                          ? getAssetFullUrl(match.acceptor.profilePicture)
                          : getAssetFullUrl(match.challenger.profilePicture)
                      }
                    />
                  </div>
                  <div className="ml-2 w-8/12">
                    <h3 className="font-sm">
                      {match.acceptor.id != user.id
                        ? match.acceptor.name
                        : match.challenger.name}
                    </h3>
                    <div className="text-sm truncate mt-1 text-white opacity-65 flex w-full">
                      <div className="flex w-1/2">
                        <label className="text-gray-11 uppercase">
                          Xếp hạng
                        </label>
                        <div className="text-white ml-[6px] flex flex-wrap">
                          <div>
                            <span className="inline-block text-center w-full">
                              {match.acceptor.id != user.id
                                ? match.acceptor.ranking.class
                                : match.challenger.ranking.class}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className=" flex w-1/2 ml-5">
                        <label className="text-gray-11 uppercase">Điểm</label>
                        <span className="text-white ml-[6px]">
                          {match.acceptor.id != user.id
                            ? match.acceptor.score
                            : match.challenger.score}
                        </span>
                      </div>
                    </div>
                  </div>
                  {match.status == "WAITING" && match.acceptor.id == user.id ? (
                    <div className="text-right flex items-center w-3/12">
                      <button
                        className="block w-full p-1 bg-[#0EB39E] text-center uppercase text-sm rounded border-2 border-[#ADF0DD] button-custom-shadow"
                        onClick={() =>
                          matchingWithAnotherPlayer(
                            match.challenger.id,
                            match.id
                          )
                        }
                      >
                        Chấp nhận
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                  {match.status == "WAITING" && match.acceptor.id != user.id ? (
                    <div className="text-right flex items-center w-3/12">
                      <button className="block w-full p-1 bg-[#0EB39E] text-center uppercase text-sm rounded border-2 border-[#ADF0DD] button-custom-shadow">
                        <span className="opacity-40">Chờ...</span>
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                  {match.status == "ACCEPTED" ? (
                    <div className="text-right flex items-center w-3/12">
                      <button
                        className="block w-full p-1 bg-[#F3D768] text-black text-center uppercase text-sm rounded border-2 border-[#F3D768] button-custom-shadow"
                        onClick={() =>
                          matchingWithAnotherPlayer(
                            match.acceptor.id != user.id
                              ? match.acceptor.id
                              : match.challenger.id,
                            match.id
                          )
                        }
                      >
                        Kết quả
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                  {match.status == "SUBMITTED" ? (
                    <div className="text-right flex items-center w-3/12">
                      <button
                        className="block w-full p-1 bg-[#F3D768] text-black text-center uppercase text-sm rounded border-2 border-[#F3D768] button-custom-shadow"
                        onClick={() => navigateToResultPage(match.id)}
                      >
                        Kết quả
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              );
          })
        : ""}
    </div>
  );
};

export default ProfileJoinedLeague;
