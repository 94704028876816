import Navbar from "components/navbar";
import {getAssetFullUrl, getRoundName} from "helpers/utils";
import { useBracketLeagueDetail, useLeagueDetail } from "hooks/league";
import { useEliminatePair, useMatchInfo, useUserByID } from "hooks/user";
import { defaultLeague } from "interfaces/league";
import { EliminateUserInterface } from "interfaces/user";
import { isEmpty } from "lodash";
import { ReactNode, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import {loaderState, userState} from "states/common";
import * as _ from 'lodash'
import {
  TETabs,
  TETabsContent,
  TETabsItem,
  TETabsPane,
} from "tw-elements-react";

export enum PlayerStatus {
  Win = "win",
  Loss = "loss",
  Unknown = "unknown",
}

export enum MatchStatus {
  Completed = "completed",
  Unknown = "unknown",
}

export const TabItem = ({
  children,
  active,
  className,
  disabled,
  onClick,
}: {
  children: ReactNode;
  active: boolean;
  disabled?: boolean;
  className?: string;
  onClick: () => void;
}) => {
  return (

    <TETabsItem
      className="py-3 customTab min-w-2 grow"
      wrapperClass="text-center grow min-w-[120px]"
      active={active}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </TETabsItem>
  );
};

const PlayerCard = ({
  playerId,
  status,
  matchStatus,
  playerScore,
  onFirstRound,
}: {
  playerId: string;
  status: PlayerStatus;
  matchStatus: MatchStatus;
  playerScore: number;
  onFirstRound?: boolean;
}) => {
  const userInfo = useUserByID(playerId);

  let themeColor = "#E4BB78";

  if (status === PlayerStatus.Win) {
    if (matchStatus === MatchStatus.Completed) themeColor = "#0EB39E";
  }

  if (!playerId) themeColor = "#777B84";

  return (
    <div
      style={{
        backgroundColor: themeColor,
      }}
      className={`flex flex-1 items-center justify-between text-[14px] text-[#EDEEF0] py-1 px-2 rounded-md`}
    >
      {userInfo ? (
        <div className="flex items-center justify-start gap-2">
          <div className="w-[28px] h-[28px] rounded-full overflow-hidden bg-white">
            <img src={userInfo?.profilePicture ? getAssetFullUrl(userInfo?.profilePicture) : ''} alt="" />
          </div>
          <p>{userInfo?.name}</p>
        </div>
      ) : (
        <p className="h-[28px] flex items-center">
          {onFirstRound ? "Chưa có đối thủ" : "Chờ kết quả vòng trước"}
        </p>
      )}

      <div className="flex items-center justify-end gap-5">
        {userInfo && (
          <p>
            {userInfo?.ranking?.class} ({userInfo?.score})
          </p>
        )}
        <div className="w-[24px] h-[24px] rounded-sm bg-white text-center text-[#777B84]">
          {playerScore || "-"}
        </div>
      </div>
    </div>
  );
};

const RoundInfo = ({
  round,
  onFirstRound,
}: {
  round: EliminateUserInterface;
  onFirstRound?: boolean;
}) => {
  const [user] = useRecoilState(userState);
  const { id = "" } = useParams();
  const isFirstPlayerCurrent = user?.id === round?.playerId;
  const matchStatus = round?.hasResult
    ? MatchStatus.Completed
    : MatchStatus.Unknown;

  const matchNotDone = matchStatus === MatchStatus.Unknown;
  const match = useMatchInfo(
    id,
    isFirstPlayerCurrent ? round?.secondPlayerId : round?.playerId, round?.leagueMatchId
  );

  const handicapPoint = match?.handicappingPoint;

  const secondIsWinner =
    (round?.winnerId === round?.secondPlayerId && isFirstPlayerCurrent) ||
    (round?.winnerId === round?.playerId && !isFirstPlayerCurrent);

  const FirstPlayer = () => {
    return (
      <PlayerCard
        playerId={round?.playerId}
        onFirstRound={onFirstRound}
        playerScore={round?.playerScore}
        matchStatus={matchStatus}
        status={
          round?.winnerId === round?.playerId && round?.playerId
            ? PlayerStatus.Win
            : PlayerStatus.Loss
        }
      />
    );
  };

  const SecondPlayer = () => {
    return (
      <PlayerCard
        playerId={round?.secondPlayerId}
        onFirstRound={onFirstRound}
        playerScore={round?.secondPlayerScore}
        matchStatus={matchStatus}
        status={
          round?.winnerId === round?.secondPlayerId && round?.secondPlayerId
            ? PlayerStatus.Win
            : PlayerStatus.Loss
        }
      />
    );
  };

  return (
    <div className="bg-[#343458] rounded-lg py-[31px]">
      <div className="flex items-center w-full">
        <div className="w-full flex flex-col gap-2">
          <section
            style={{
              marginRight: matchNotDone ? "36px" : "0",
            }}
            className="flex items-center"
          >
            <img src="/top.svg" alt="" />

            {isFirstPlayerCurrent ? <FirstPlayer /> : <SecondPlayer />}
          </section>
          <section
            className="flex items-center"
            style={{
              marginRight: matchNotDone ? "36px" : "0",
            }}
          >
            <img src="/bottom.svg" alt="" />
            {isFirstPlayerCurrent ? <SecondPlayer /> : <FirstPlayer />}
          </section>
        </div>

        {!matchNotDone && (
          <div
            className={`h-[28px] ${
              secondIsWinner ? "mt-[64px]" : "-mt-[20px]"
            }`}
          >
            <img src="/winner.svg" alt="" />
          </div>
        )}
      </div>
      {round && round.playerId && round.secondPlayerId ? <p className="text-[12px] ml-8 mt-4">
        {handicapPoint === undefined || handicapPoint === 0 ? (
            <p className="text-[#0BD8B6]  font-exo font-thin">
              {<span className="text-[#0BD8B6]">
                  {match?.challenger?.name}
                </span>} đánh đồng với <span className="text-[#0BD8B6]">{match?.acceptor?.name}</span>
            </p>
        ) : (
            <>
              {handicapPoint < 0 ? (
                  <p className="text-[#FFCA16] font-exo font-thin">
                    <span className="text-[#0BD8B6]">{match?.acceptor?.name}</span>{" "}
                    chấp{" "}
                    <span className="text-[#0BD8B6]">
                  {match?.challenger?.name}
                </span>{" "}
                    {Math.abs(handicapPoint)} ván
                  </p>
              ) : (
                  <p className="text-[#FFCA16] font-exo font-thin">
                <span className="text-[#0BD8B6]">
                  {match?.challenger?.name}
                </span>{" "}
                    chấp{" "}
                    <span className="text-[#0BD8B6]">{match?.acceptor?.name}</span>{" "}
                    {Math.abs(handicapPoint)} ván
                  </p>
              )}
            </>
        )}
      </p> : <></>}

    </div>
  );
};

const CustomTabPane = ({
                         children,
                         isActive,
                         disabled,
                         onClick,
                       }: {
  children: ReactNode;
  isActive: boolean;
  disabled?: boolean;
  onClick: () => void;
}) => {
  return (
      <div
          onClick={() => (disabled ? null : onClick())}
          className={`text-[14px] rounded-md px-8 py-[1px] shrink-0 ${
              isActive ? "bg-[#1CB4A3]" : ""
          } ${
              !disabled || isActive
                  ? "cursor-pointer text-white"
                  : "cursor-pointer text-[#848484]"
          }
      
      `}
      >
        {children}
      </div>
  );
};

const UserLeagueSchedule = () => {
  const {id = "", playerId = ""} = useParams();

  const league = useLeagueDetail(id, defaultLeague);
  const [activeTab, setActiveTab] = useState<any>(undefined);
  const [maxRound, setMaxRound] = useState(0);
  const pairInfos = useEliminatePair(id, playerId);
  const [currentPair, setCurrentPair] = useState<any>();
  const bracketInfo = useBracketLeagueDetail(id);
  const [, setLoader] = useRecoilState(loaderState);
  const [canRender, setCanRender] = useState(false);

  const nav = useNavigate();

  useEffect(() => {
    setLoader(true)
  }, []);

  useEffect(() => {
    if (isEmpty(bracketInfo)) return;
    if (pairInfos.length && pairInfos[0]) {
      setMaxRound(pairInfos[0].roundStep - 1)
      setActiveTab(pairInfos[0].roundStep - 1)
    } else {
      setActiveTab(0)
    }

    setLoader(false)
    let activeData
    if (pairInfos[0]) {
      activeData =  getActiveRoundData(pairInfos[0].roundStep - 1)
    }
    if (activeData) {
      console.log(activeData.data, playerId)
      const currentUserPair = _.find(activeData.data, item => {
        return item.playerId === playerId || item.secondPlayerId === playerId
      })
      if (currentUserPair) {
        setCurrentPair(currentUserPair)
      }
    }
    setCanRender(true)
  }, [bracketInfo]);

  const getActiveRoundData = (round: number) => {
    if (bracketInfo && bracketInfo.roundData.length > 0) {
      return bracketInfo.roundData[round]
    }
    return null;
  };

  const changeTabData = (tab: number) => {
    setLoader(true)
    setActiveTab(tab);
    const activeData =  getActiveRoundData(tab)
    if (activeData) {
      const currentUserPair = _.find(activeData.data, item => {
        return item.playerId === playerId || item.secondPlayerId === playerId
      })
      if (currentUserPair) {
        setCurrentPair(currentUserPair)
      }
    }
    setLoader(false)
  }

  return (
    <div className="py-4 mx-2">
      <Navbar title="LỊCH THI ĐẤU CÁ NHÂN" />

      <section>
        <h2 className="font-semibold text-[30px]">{league?.name}</h2>
        <p className="text-[#B0B4BA] text-[14px]">
          {league.startDateObj.format("DD/MM/YY")} -{" "}
          {league.endDateObj.format("DD/MM/YY")}
        </p>
      </section>

      <section className="my-5 gap-y-4 w-full">
        <div className="flex items-center justify-between font-semibold text-[16px]">
          <h5>Lịch thi đấu</h5>
          <p
            role="button"
            onClick={() => nav(`/league/playoff/${id}/schedule`)}
            className="text-[#0BD8B6]"
          >
            Xem lịch thi đấu đầy đủ
          </p>
        </div>
        {activeTab !== undefined ? <div className="league-content-tab w-full mt-3">
          <div className="overflow-x-scroll w-full border border-white rounded-md p-1">
            <TETabs
                style={{
                  flexWrap: "nowrap",
                  marginBottom: "0",
                }}
            >
              {bracketInfo?.roundData?.map((round, index) => {
                const name = getRoundName(round?.roundName);
                return (
                    <CustomTabPane
                        key={index}
                        isActive={activeTab === index}
                        disabled={index > maxRound || index === activeTab}
                        onClick={() => changeTabData(index)}
                    >
                      {name}
                    </CustomTabPane>
                );
              })}
            </TETabs>
          </div>

          <TETabsContent>
            {bracketInfo?.roundData?.map((_, index) => {
              if (index > maxRound) {
                return (
                    <TETabsPane key={index} show={activeTab === index}></TETabsPane>
                );
              } else {
                return (
                    <TETabsPane key={index} show={activeTab === index}>
                      <RoundInfo
                          round={currentPair}
                          onFirstRound={index === 0}
                      />
                    </TETabsPane>
                );
              }

            })}
          </TETabsContent>
        </div> : <></>}

      </section>
    </div>
  );
};

export default UserLeagueSchedule;
